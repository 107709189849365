import dynamic from 'next/dynamic';

import { fetchProjects, fetchServices } from '../services/home';
import AppLayout from '../layout/AppLayout';
import AppHead from '../components/AppHead';
import { useAppStateContext } from '../hooks/context';
// import AppPartner from '../components/AppPartner/AppPartner';

const DynamicAppGetInTouch = dynamic(() => import('../components/AppGetInTouch/AppGetInTouch'));
const AppPartner = dynamic(() => import('../components/AppPartner/AppPartner'));
const DynamicAppOurServices = dynamic(() => import('../components/AppOurServices/AppOurServices'));
const DinamicAppProjectsSlider = dynamic(() =>
  import('../components/AppProjectsSlider/AppProjectsSlider'),
);

export default function Home({ data }) {
  const { parameters } = useAppStateContext().context;

  const metaTitle = parameters?.data?.find((x) => x.keyword === 'meta_title')?.value;
  const metaDescription = parameters?.data?.find((x) => x.keyword === 'meta_description')?.value;

  return (
    <AppLayout>
      <AppHead metaTitle={metaTitle} metaDescription={metaDescription}/>
      <DynamicAppGetInTouch slider={data.services} />
      <AppPartner />
      <DynamicAppOurServices services={data.services} />
      <DinamicAppProjectsSlider projects={data.projects.data} />
    </AppLayout>
  );
}
export async function getServerSideProps({ locale }) {
  const [services, projects] = await Promise.all([
    fetchServices({ locale }),
    fetchProjects({ locale }),
  ]);

  return {
    props: {
      data: {
        services,
        projects,
      },
    },
  };
}
