import Head from 'next/head';
import { useRouter } from 'next/router';
import urlJoin from 'url-join';

export default function AppHead({ metaTitle, metaDescription, imageUrl, noIndex, language_urls }) {
  const langPrefix = (lang, defaultLang) => (lang === defaultLang ? '' : `/${lang}`);

  const router = useRouter();

  const locale = router.locale === 'ka' ? '' : '/en';
  const _pathname = router.asPath === '/' ? '' : router.asPath;
  const websiteUrl = process.env.WEBSITE_URL;
  const normalizedTitle = [metaTitle, 'Artmedia'].filter((a) => a).join(' | ');

  // change website url
  const canonical = `${process.env.WEBSITE_URL}${locale}${_pathname}`;

  const openGraphImageUrl = imageUrl || urlJoin(websiteUrl, '/images/opengraph-1200x630.jpg');
  const openGraphTitle = metaTitle || 'Artmedia';
  const openGraphDescription = metaDescription
    ? metaDescription
    : 'Web & Mobile Development Company';

  const description = metaDescription ? metaDescription : 'Web & Mobile Development Company';

  const defaultLocale = {
    code: 'x-default',
    url: urlJoin(websiteUrl, langPrefix(router.locale, router.defaultLocale), _pathname),
  };

  const _localesUrls = (language_urls || []).map((item) => {
    return {
      code: item.lang === 'ge' ? 'ka' : item.lang,
      url: urlJoin(
        websiteUrl,
        langPrefix(item.lang === 'ge' ? 'ka' : item.lang, router.defaultLocale),
        item.url,
      ),
    };
  });

  const __localesUrls = [..._localesUrls, defaultLocale];

  return (
    <Head>
      <link rel="icon" href={'/artmedia.svg'} />
      <title>{normalizedTitle}</title>
      <link rel="publisher" href="https://artmedia.ge" />
      <meta name="author" content="Artmedia LLC" />
      <link rel="canonical" href={canonical} />
      <meta name="robots" content={noIndex ? 'noindex, nofollow' : 'index, follow'} />
      {__localesUrls.map((item, index) => (
        <link key={index} rel="alternate" hrefLang={item.code} href={item.url} />
      ))}

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {description && <meta name="description" content={description} />}

      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      {openGraphTitle && <meta property="og:title" content={openGraphTitle} />}
      {openGraphDescription && <meta property="og:description" content={openGraphDescription} />}
      {openGraphImageUrl && <meta property="og:image" content={openGraphImageUrl} />}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={canonical} />
      <meta name="facebook-domain-verification" content="v1r6sj4acr43mp0s4hfywf29rsxc62" />
      {openGraphTitle && <meta property="twitter:title" content={openGraphTitle} />}
      {openGraphDescription && (
        <meta property="twitter:description" content={openGraphDescription} />
      )}
      {openGraphImageUrl && <meta property="twitter:image" content={openGraphImageUrl} />}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            name: 'Artmedia',
            url: 'https://artmedia.ge',
            logo: 'http://artmedia.ge/images/logo.svg',
            email: 'welcome@artmedia.ge',
            telephone: '+995557212123',
            sameAs: [
              'https://www.facebook.com/webArtmedia/',
              'https://www.instagram.com/artmedia_web/',
            ],
            contactPoint: [
              {
                '@type': 'ContactPoint',
                email: 'welcome@artmedia.ge',
                contactType: 'customer support',
                url: 'http://artmedia.ge/contact',
              },
            ],
            address: {
              '@type': 'PostalAddress',
              addressLocality: 'Tbilisi, Georgia',
              postalCode: '0179',
              streetAddress: '4 Shio Mghvimeli Street',
            },
          }),
        }}
      />
    </Head>
  );
}
